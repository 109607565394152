import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { getCourseID, isEnrolled, isTeacher } from "../Helpers";
import { useActions, useAppState } from "../overmind";
import StudentPage from "./StudentPage";
import TeacherPage from "./TeacherPage";
const CoursePage = () => {
    const state = useAppState();
    const actions = useActions();
    const courseID = getCourseID();
    const enrollment = state.enrollmentsByCourseID[courseID.toString()];
    useEffect(() => {
        if (!state.showFavorites) {
            actions.toggleFavorites();
        }
        actions.setActiveCourse(courseID);
    }, [courseID]);
    if (state.enrollmentsByCourseID[courseID.toString()] && isEnrolled(enrollment)) {
        if (isTeacher(enrollment)) {
            return React.createElement(TeacherPage, null);
        }
        return React.createElement(StudentPage, null);
    }
    else {
        return React.createElement(Redirect, { to: "/" });
    }
};
export default CoursePage;
