import React from "react";
import { isManuallyGraded, Color } from "../../Helpers";
import { useActions, useAppState } from "../../overmind";
import Button, { ButtonType } from "../admin/Button";
import ReviewInfo from "./ReviewInfo";
import ReviewResult from "../ReviewResult";
const ReviewForm = () => {
    const state = useAppState();
    const actions = useActions();
    if (!state.selectedSubmission) {
        return React.createElement("div", null, "No submission selected");
    }
    const assignment = state.selectedAssignment;
    if (!assignment) {
        return React.createElement("div", null, "No Submission");
    }
    const isAuthor = (review) => {
        return review?.ReviewerID === state.self.ID;
    };
    const reviewers = assignment.reviewers ?? 0;
    const reviews = state.review.reviews.get(state.selectedSubmission.ID) ?? [];
    const selectReviewButton = [];
    reviews.forEach((review, index) => {
        selectReviewButton.push(React.createElement(Button, { key: review.ID.toString(), text: review.ready ? "Ready" : "In Progress", color: review.ready ? Color.GREEN : Color.YELLOW, type: ButtonType.BUTTON, className: `mr-1 ${state.review.selectedReview === index ? "active border border-dark" : ""}`, onClick: () => { actions.review.setSelectedReview(index); } }));
    });
    if ((reviews.length === 0 || reviews.some(review => !isAuthor(review))) && (reviewers - reviews.length) > 0) {
        selectReviewButton.push(React.createElement(Button, { key: "add", text: "Add Review", color: Color.BLUE, type: ButtonType.BUTTON, className: "mr-1", onClick: async () => { await actions.review.createReview(); } }));
    }
    if (!isManuallyGraded(assignment)) {
        return React.createElement("div", null, "This assignment is not for manual grading.");
    }
    else {
        return (React.createElement("div", { className: "col lab-sticky reviewLabResult" },
            React.createElement("div", { className: "mb-1" }, selectReviewButton),
            state.review.currentReview ? (React.createElement(React.Fragment, null,
                React.createElement(ReviewInfo, { review: state.review.currentReview }),
                React.createElement(ReviewResult, { review: state.review.currentReview }))) : null));
    }
};
export default ReviewForm;
