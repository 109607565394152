import React from "react";
import { Route, Switch, useHistory } from "react-router";
import { Color, getCourseID, isManuallyGraded } from "../Helpers";
import { useActions, useAppState } from "../overmind";
import Card from "../components/Card";
import GroupPage from "./GroupPage";
import Members from "../components/Members";
import RedirectButton from "../components/RedirectButton";
import Results from "../components/Results";
import Assignments from "../components/teacher/Assignments";
import Alerts from "../components/alerts/Alerts";
const ReviewResults = () => React.createElement(Results, { review: true });
const RegularResults = () => React.createElement(Results, { review: false });
const TeacherPage = () => {
    const state = useAppState();
    const actions = useActions();
    const courseID = getCourseID();
    const history = useHistory();
    const root = `/course/${courseID}`;
    const courseHasManualGrading = state.assignments[courseID.toString()]?.some(assignment => isManuallyGraded(assignment));
    const members = {
        title: "View Members",
        notification: state.pendingEnrollments.length > 0 ? { color: Color.YELLOW, text: "Pending enrollments" } : undefined,
        text: "View all students, and approve new enrollments.",
        buttonText: "Members", to: `${root}/members`
    };
    const groups = {
        title: "Manage Groups",
        notification: state.pendingGroups.length > 0 ? { color: Color.YELLOW, text: "Pending groups" } : undefined,
        text: "View, edit or delete course groups.",
        buttonText: "Groups", to: `${root}/groups`
    };
    const results = { title: "View results", text: "View results for all students in the course.", buttonText: "Results", to: `${root}/results` };
    const assignments = { title: "Manage Assignments", text: "View and edit assignments.", buttonText: "Assignments", to: `${root}/assignments` };
    const updateAssignments = { title: "Update Course Assignments", text: "Fetch assignments from GitHub.", buttonText: "Update Assignments", onclick: () => actions.updateAssignments(courseID) };
    const review = { title: "Review Assignments", text: "Review assignments for students.", buttonText: "Review", to: `${root}/review` };
    return (React.createElement("div", { className: "box" },
        React.createElement(RedirectButton, { to: root }),
        React.createElement(Alerts, null),
        React.createElement("div", { className: "row", hidden: history.location.pathname != root },
            courseHasManualGrading && React.createElement(Card, { ...review }),
            React.createElement(Card, { ...results }),
            React.createElement(Card, { ...groups }),
            React.createElement(Card, { ...members }),
            React.createElement(Card, { ...assignments }),
            React.createElement(Card, { ...updateAssignments })),
        React.createElement(Switch, null,
            React.createElement(Route, { path: `/course/:id/groups`, exact: true, component: GroupPage }),
            React.createElement(Route, { path: "/course/:id/members", component: Members }),
            React.createElement(Route, { path: "/course/:id/review", component: ReviewResults }),
            React.createElement(Route, { path: "/course/:id/results", component: RegularResults }),
            React.createElement(Route, { path: "/course/:id/assignments", component: Assignments }))));
};
export default TeacherPage;
