import React from 'react';
import Alerts from '../components/alerts/Alerts';
const AboutPage = () => {
    return (React.createElement("div", null,
        React.createElement("div", { className: "banner" },
            React.createElement("div", { key: "jb", id: "0", className: "jumbotron" },
                React.createElement("div", { key: "cblock", className: "centerblock container" },
                    React.createElement("h1", null, "Automated student feedback"),
                    React.createElement("p", null,
                        React.createElement("strong", null, "QuickFeed "),
                        "provides instantaneous feedback to students on their programming assignments. It is also a valuable tool for teachers when grading lab assignments."),
                    React.createElement("p", null,
                        React.createElement("a", { className: "btn btn-primary btn-lg", href: "#quickfeed", role: "button" }, "Learn more \u00BB"))))),
        React.createElement(Alerts, null),
        React.createElement("div", { key: "container", className: "container" },
            React.createElement("div", { key: "rowheader", className: "row marketing" },
                React.createElement("div", { key: "gh", className: "col-lg-4" },
                    React.createElement("img", { className: "img-circle", src: "/assets/img/GitHub-Mark-120px-plus.png", alt: "GitHub logo", style: { width: "140px", height: "140px" } }),
                    React.createElement("h2", null, "GitHub Integration"),
                    React.createElement("p", null, "Manage all students and courses on GitHub. Each student gets their own repository. Teachers get separate repositories for publishing assignments and information to students. All taken care of automatically."),
                    React.createElement("p", null,
                        React.createElement("a", { className: "btn btn-default", href: "#versioncontrol", role: "button" }, "View details \u00BB"))),
                React.createElement("div", { key: "ci", className: "col-lg-4" },
                    React.createElement("img", { className: "img-circle", src: "/assets/img/overlapping-arrows.png", style: { width: "140px", height: "140px" } }),
                    React.createElement("h2", null, "Continuous Integration"),
                    React.createElement("p", null, "Instantaneous feedback to students on how well their code performs. Students can quickly identify what they need to focus on to improve. All customizable for the teaching staff."),
                    React.createElement("p", null,
                        React.createElement("a", { className: "btn btn-default", href: "#ci", role: "button" }, "View details \u00BB"))),
                React.createElement("div", { key: "grade", className: "col-lg-4" },
                    React.createElement("img", { className: "img-circle", src: "/assets/img/Aplus2.png", alt: "A+ image", style: { width: "140px", height: "140px" } }),
                    React.createElement("h2", null, "Fair Grading"),
                    React.createElement("p", null, "On due date of an assignment, the most recent version of each student's code is available through GitHub. Easily accessible for the teachers. Together with latest build log, this makes grading easier and more fair."),
                    React.createElement("p", null,
                        React.createElement("a", { className: "btn btn-default", href: "#grading", role: "button" }, "View details \u00BB")))),
            React.createElement("section", { id: "#quickfeed" },
                React.createElement("hr", { className: "featurette-divider" }),
                React.createElement("div", { key: "row1", className: "row featurette" },
                    React.createElement("div", { key: "c1r1", className: "col-md-7" },
                        React.createElement("h2", { className: "featurette-heading" },
                            "QuickFeed: ",
                            React.createElement("span", { className: "text-muted" }, "Automated student feedback")),
                        React.createElement("p", { className: "lead" }, "QuickFeed aims to provide students with fast feedback on their lab assignments, and is designed to help students learn about state-of-the-art tools used in the industry. QuickFeed builds upon version control systems and continuous integration. When students upload code to their repositories, QuickFeed automatically builds their code and provides feedback based on tests supplied by the teaching staff. When grading assignments, teaching staff can access the results of test execution and have a valuable tool in the grading process.")),
                    React.createElement("div", { key: "c2r1", className: "col-md-5" },
                        React.createElement("img", { className: "featurette-image img-responsive about", src: "/assets/img/intro1.png", alt: "Generic placeholder image" })))),
            React.createElement("section", { id: "versioncontrol" },
                React.createElement("hr", { className: "featurette-divider" }),
                React.createElement("div", { key: "row2", className: "row featurette" },
                    React.createElement("div", { key: "c1r2", className: "col-md-5" },
                        React.createElement("img", { className: "featurette-image img-responsive about", src: "/assets/img/intro3.png", alt: "Generic placeholder image" })),
                    React.createElement("div", { key: "c2r2", className: "col-md-7" },
                        React.createElement("h2", { className: "featurette-heading" },
                            "GitHub Integration: ",
                            React.createElement("span", { className: "text-muted" }, "Managing courses and students")),
                        React.createElement("p", { className: "lead" }, "A course is an organization on GitHub. Students get access to their own private GitHub repository. Uploading their code for review or grading, students can learn to use git for version control.")))),
            React.createElement("section", { id: "ci" },
                React.createElement("hr", { className: "featurette-divider" }),
                React.createElement("div", { key: "row3", className: "row featurette" },
                    React.createElement("div", { key: "c1r3", className: "col-md-7" },
                        React.createElement("h2", { className: "featurette-heading" },
                            "Continuous Integration: ",
                            React.createElement("span", { className: "text-muted" }, "builds and tests student code. ")),
                        React.createElement("p", { className: "lead" }, "As code gets pushed up to GitHub, an automatic build process defined by the teacher, generates feedback to students. When the build process is completed, student gets immediate access to this feedback on their personal course page. Tests defined by either teachers or students will be processed and tell students about their progress on the assignments.")),
                    React.createElement("div", { key: "c2r3", className: "col-md-5" },
                        React.createElement("img", { className: "featurette-image img-responsive about", src: "/assets/img/intro2.png", alt: "Generic placeholder image" })))),
            React.createElement("section", { id: "grading" },
                React.createElement("hr", { className: "featurette-divider" }),
                React.createElement("div", { key: "row4", className: "row featurette" },
                    React.createElement("div", { key: "c1r4", className: "col-md-5" },
                        React.createElement("img", { className: "featurette-image img-responsive about", src: "/assets/img/intro4.png", alt: "Generic placeholder image" })),
                    React.createElement("div", { key: "c2r4", className: "col-md-7" },
                        React.createElement("h2", { className: "featurette-heading" },
                            "Grading: ",
                            React.createElement("span", { className: "text-muted" }, "Easy and Fair")),
                        React.createElement("p", { className: "lead" }, "On the due date, teachers can access the test results and use this as a tool in the grading process. The teaching staff will immediately know which of their tests passed, and how much of the code is covered by the tests.")))),
            React.createElement("footer", null,
                React.createElement("hr", null),
                React.createElement("p", { className: "pull-right" },
                    React.createElement("a", { href: "#" }, "Back to top"))))));
};
export default AboutPage;
