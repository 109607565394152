import { useHistory } from "react-router";
import { assignmentStatusText, getFormattedTime, getCourseID, getStatusByUser } from "../Helpers";
import { useAppState } from "../overmind";
import { Submission } from "../../proto/qf/types_pb";
import ProgressBar, { Progress } from "./ProgressBar";
import React from "react";
const CourseLabs = () => {
    const state = useAppState();
    const history = useHistory();
    const courseID = getCourseID().toString();
    const labs = [];
    const redirectTo = (assignmentID) => {
        history.push(`/course/${courseID}/lab/${assignmentID.toString()}`);
    };
    if (state.assignments[courseID] && state.submissions[courseID]) {
        state.assignments[courseID].forEach(assignment => {
            const assignmentIndex = assignment.order - 1;
            const submission = state.submissions[courseID][assignmentIndex] ?? new Submission();
            labs.push(React.createElement("li", { key: assignment.ID.toString(), className: "list-group-item border clickable mb-2 labList", onClick: () => redirectTo(assignment.ID) },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-8" },
                        React.createElement("strong", null, assignment.name)),
                    React.createElement("div", { className: "col-4 text-center" },
                        React.createElement("strong", null, "Deadline:"))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-5" },
                        React.createElement(ProgressBar, { courseID: courseID, assignmentIndex: assignmentIndex, submission: submission, type: Progress.LAB })),
                    React.createElement("div", { className: "col-3 text-center" }, assignmentStatusText(assignment, submission, getStatusByUser(submission, state.self.ID))),
                    React.createElement("div", { className: "col-4 text-center" }, getFormattedTime(assignment.deadline, true)))));
        });
    }
    return (React.createElement("ul", { className: "list-group" }, labs));
};
export default CourseLabs;
