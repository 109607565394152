import React, { useState } from "react";
import { Color, EnrollmentSort, EnrollmentStatus, EnrollmentStatusBadge, getCourseID, getFormattedTime, isPending, isTeacher, sortEnrollments } from "../Helpers";
import { useAppState, useActions } from "../overmind";
import { Enrollment_UserStatus } from "../../proto/qf/types_pb";
import Search from "./Search";
import DynamicTable from "./DynamicTable";
import DynamicButton from "./DynamicButton";
import Button, { ButtonType } from "./admin/Button";
const Members = () => {
    const state = useAppState();
    const actions = useActions();
    const courseID = getCourseID();
    const [sortBy, setSortBy] = useState(EnrollmentSort.Status);
    const [descending, setDescending] = useState(false);
    const [edit, setEditing] = useState(false);
    const setSort = (sort) => {
        if (sortBy === sort) {
            setDescending(!descending);
        }
        setSortBy(sort);
    };
    let enrollments = [];
    if (state.courseEnrollments[courseID.toString()]) {
        enrollments = state.courseEnrollments[courseID.toString()].slice();
    }
    const pending = state.pendingEnrollments;
    const header = [
        { value: "Name", onClick: () => setSort(EnrollmentSort.Name) },
        { value: "Email", onClick: () => setSort(EnrollmentSort.Email) },
        { value: "Student ID", onClick: () => setSort(EnrollmentSort.StudentID) },
        { value: "Activity", onClick: () => setSort(EnrollmentSort.Activity) },
        { value: "Approved", onClick: () => setSort(EnrollmentSort.Approved) },
        { value: "Slipdays", onClick: () => { setSort(EnrollmentSort.Slipdays); } },
        { value: "Role", onClick: () => { setSort(EnrollmentSort.Status); } },
    ];
    const members = sortEnrollments(enrollments, sortBy, descending).map(enrollment => {
        const data = [];
        data.push(enrollment.user ? enrollment.user.Name : "");
        data.push(enrollment.user ? enrollment.user.Email : "");
        data.push(enrollment.user ? enrollment.user.StudentID : "");
        data.push(getFormattedTime(enrollment.lastActivityDate));
        data.push(enrollment.totalApproved.toString());
        data.push(enrollment.slipDaysRemaining.toString());
        if (isPending(enrollment)) {
            data.push(React.createElement("div", { className: "d-flex" },
                React.createElement(DynamicButton, { text: "Accept", color: Color.GREEN, type: ButtonType.BADGE, className: "mr-2", onClick: () => actions.updateEnrollment({ enrollment, status: Enrollment_UserStatus.STUDENT }) }),
                React.createElement(DynamicButton, { text: "Reject", color: Color.RED, type: ButtonType.BADGE, onClick: () => actions.updateEnrollment({ enrollment, status: Enrollment_UserStatus.NONE }) })));
        }
        else {
            data.push(edit ? (React.createElement("div", { className: "d-flex" },
                React.createElement(DynamicButton, { text: isTeacher(enrollment) ? "Demote" : "Promote", color: isTeacher(enrollment) ? Color.YELLOW : Color.BLUE, type: ButtonType.BADGE, className: "mr-2", onClick: () => actions.updateEnrollment({ enrollment, status: isTeacher(enrollment) ? Enrollment_UserStatus.STUDENT : Enrollment_UserStatus.TEACHER }) }),
                React.createElement(DynamicButton, { text: "Reject", color: Color.RED, type: ButtonType.BADGE, onClick: () => actions.updateEnrollment({ enrollment, status: Enrollment_UserStatus.NONE }) }))) :
                React.createElement("i", { className: EnrollmentStatusBadge[enrollment.status] }, EnrollmentStatus[enrollment.status]));
        }
        return data;
    });
    return (React.createElement("div", { className: 'container' },
        React.createElement("div", { className: "row no-gutters pb-2" },
            React.createElement("div", { className: "col-md-6" },
                React.createElement(Search, null)),
            React.createElement("div", { className: "ml-auto" },
                React.createElement(Button, { text: edit ? "Done" : "Edit", color: edit ? Color.RED : Color.BLUE, type: ButtonType.BUTTON, onClick: () => setEditing(!edit) })),
            pending?.length > 0 ?
                React.createElement("div", { style: { marginLeft: "10px" } },
                    React.createElement(DynamicButton, { text: "Approve All", color: Color.GREEN, type: ButtonType.BUTTON, onClick: () => actions.approvePendingEnrollments() })) : null),
        React.createElement("div", null,
            React.createElement(DynamicTable, { header: header, data: members }))));
};
export default Members;
