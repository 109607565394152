import React from "react";
import { Link, useHistory } from "react-router-dom";
const NavBarLink = (props) => {
    const history = useHistory();
    const icons = [];
    if (props.icons) {
        props.icons.forEach((icon, index) => {
            if (icon) {
                icons.push(React.createElement("div", { key: index, id: "icon", className: icon.classname + " ml-2" }, icon.text));
            }
        });
    }
    return (React.createElement("li", { onClick: () => history.push(props.link.to) },
        React.createElement("div", { className: "col", id: "title" },
            React.createElement(Link, { to: props.link.to }, props.link.text)),
        React.createElement("div", { className: "col" },
            icons ? icons : null,
            props.jsx ? props.jsx : null)));
};
export default NavBarLink;
