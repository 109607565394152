import React, { useState } from "react";
import { GradingBenchmark } from "../../../proto/qf/types_pb";
import { useActions } from "../../overmind";
const EditBenchmark = ({ children, benchmark, assignment }) => {
    const actions = useActions();
    const [editing, setEditing] = useState(false);
    const [add, setAdd] = useState(benchmark ? false : true);
    const bm = benchmark
        ? benchmark.clone()
        : new GradingBenchmark();
    const handleBenchmark = (event) => {
        const { value } = event.currentTarget;
        if (event.key === "Enter") {
            bm.AssignmentID = assignment.ID;
            actions.createOrUpdateBenchmark({ benchmark: bm, assignment: assignment });
            setEditing(false);
        }
        else {
            bm.heading = value;
        }
    };
    const handleBlur = () => {
        if (benchmark) {
            bm.heading = benchmark.heading;
        }
        else {
            bm.heading = "";
            setAdd(true);
        }
        setEditing(false);
    };
    if (add) {
        return (React.createElement("div", { className: "list-group-item list-group-item-primary" },
            React.createElement("button", { className: "btn btn-primary", name: "submit", onClick: () => { setAdd(false); setEditing(true); } }, "Add Benchmark")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "list-group-item list-group-item-primary" }, editing
            ? React.createElement("input", { className: "form-control", type: "text", autoFocus: true, defaultValue: bm?.heading, onBlur: () => handleBlur(), onClick: () => setEditing(true), onKeyUp: e => { handleBenchmark(e); } })
            : React.createElement("span", { onClick: () => setEditing(true) },
                bm?.heading,
                React.createElement("span", { className: "badge badge-danger float-right clickable", onClick: () => actions.deleteBenchmark({ benchmark: benchmark, assignment: assignment }) }, "Delete"))),
        children));
};
export default EditBenchmark;
