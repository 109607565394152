export const Status = {
    Active: "active",
    Inactive: "inactive",
    ActiveLab: "activelab"
};
export const NoSubmission = "No submission";
export const ScreenSize = {
    Small: 576,
    Medium: 768,
    Large: 992,
    ExtraLarge: 1200
};
