import React, { useEffect } from "react";
import { Submission_Status } from "../../proto/qf/types_pb";
import { Color, hasAllStatus, isManuallyGraded } from "../Helpers";
import { useActions, useAppState } from "../overmind";
import { ButtonType } from "./admin/Button";
import DynamicButton from "./DynamicButton";
const ManageSubmissionStatus = () => {
    const actions = useActions();
    const state = useAppState();
    const assignment = state.selectedAssignment;
    const [rebuilding, setRebuilding] = React.useState(false);
    const [updating, setUpdating] = React.useState(Submission_Status.NONE);
    const [viewIndividualGrades, setViewIndividualGrades] = React.useState(false);
    useEffect(() => {
        return () => {
            setViewIndividualGrades(false);
        };
    }, [state.selectedSubmission]);
    const handleRebuild = async () => {
        if (rebuilding) {
            return;
        }
        setRebuilding(true);
        await actions.rebuildSubmission({ owner: state.submissionOwner, submission: state.selectedSubmission });
        setRebuilding(false);
    };
    const handleSetStatus = async (status) => {
        if (updating !== Submission_Status.NONE) {
            return;
        }
        setUpdating(status);
        await actions.updateSubmission({ owner: state.submissionOwner, submission: state.selectedSubmission, status });
        setUpdating(Submission_Status.NONE);
    };
    const handleSetGrade = async (grade, status) => {
        if (updating !== Submission_Status.NONE) {
            return;
        }
        setUpdating(status);
        await actions.updateGrade({ grade, status });
        setUpdating(Submission_Status.NONE);
    };
    const getUserName = (userID) => {
        if (!assignment) {
            return "";
        }
        const user = state.courseEnrollments[assignment.CourseID.toString()].find(enrollment => enrollment.userID === userID)?.user;
        if (!user) {
            return "";
        }
        return user.Name;
    };
    const getButtonType = (status) => {
        const submission = state.selectedSubmission;
        const grades = submission?.Grades;
        if (!grades) {
            return ButtonType.OUTLINE;
        }
        if (hasAllStatus(submission, status)) {
            return ButtonType.BUTTON;
        }
        return ButtonType.OUTLINE;
    };
    const getGradeButtonType = (grade, status) => {
        if (grade.Status === status) {
            return ButtonType.BUTTON;
        }
        return ButtonType.OUTLINE;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row mb-1 ml-auto mr-auto" },
            state.selectedSubmission?.Grades && state.selectedSubmission.Grades.length > 1 && (React.createElement(DynamicButton, { text: viewIndividualGrades ? "All Grades" : "Individual Grades", color: Color.GRAY, type: ButtonType.OUTLINE, className: "col mr-2", onClick: async () => setViewIndividualGrades(!viewIndividualGrades) })),
            assignment && !isManuallyGraded(assignment) && (React.createElement(DynamicButton, { text: rebuilding ? "Rebuilding..." : "Rebuild", color: Color.BLUE, type: ButtonType.OUTLINE, className: "col mr-2", onClick: handleRebuild }))),
        !viewIndividualGrades && (React.createElement("div", { className: "row m-auto" },
            React.createElement(DynamicButton, { text: "Approve", color: Color.GREEN, type: getButtonType(Submission_Status.APPROVED), className: "col mr-2", onClick: () => handleSetStatus(Submission_Status.APPROVED) }),
            React.createElement(DynamicButton, { text: "Revision", color: Color.YELLOW, type: getButtonType(Submission_Status.REVISION), className: "col mr-2", onClick: () => handleSetStatus(Submission_Status.REVISION) }),
            React.createElement(DynamicButton, { text: "Reject", color: Color.RED, type: getButtonType(Submission_Status.REJECTED), className: "col mr-2", onClick: () => handleSetStatus(Submission_Status.REJECTED) }))),
        viewIndividualGrades &&
            React.createElement("table", { className: "table" },
                React.createElement("tbody", null, state.selectedSubmission?.Grades.map((grade) => (React.createElement("tr", { key: grade.UserID.toString() },
                    React.createElement("td", { className: "td-center word-break" }, getUserName(grade.UserID)),
                    React.createElement("td", null,
                        React.createElement(DynamicButton, { text: "Approve", color: Color.GREEN, type: getGradeButtonType(grade, Submission_Status.APPROVED), className: "mr-2", onClick: () => handleSetGrade(grade, Submission_Status.APPROVED) })),
                    React.createElement("td", null,
                        React.createElement(DynamicButton, { text: "Revision", color: Color.YELLOW, type: getGradeButtonType(grade, Submission_Status.REVISION), className: "mr-2", onClick: () => handleSetGrade(grade, Submission_Status.REVISION) })),
                    React.createElement("td", null,
                        React.createElement(DynamicButton, { text: "Reject", color: Color.RED, type: getGradeButtonType(grade, Submission_Status.REJECTED), className: "mr-2", onClick: () => handleSetGrade(grade, Submission_Status.REJECTED) })))))))));
};
export default ManageSubmissionStatus;
