import React, { useEffect } from "react";
import { useActions } from "../overmind";
const Search = ({ placeholder, setQuery, className, children }) => {
    const actions = useActions();
    useEffect(() => {
        return () => { actions.setQuery(""); };
    }, []);
    return (React.createElement("div", { className: `input-group ${className}` },
        React.createElement("input", { type: "text", className: "form-control", placeholder: placeholder ? placeholder : "Search", onKeyUp: (e) => setQuery ? setQuery(e.currentTarget.value.toLowerCase()) : actions.setQuery(e.currentTarget.value.toLowerCase()) }),
        children));
};
export default Search;
