import React from "react";
const SubmissionScore = ({ score, totalWeight, }) => {
    const className = score.Score === score.MaxScore ? "passed" : "failed";
    const percentage = (score.Score / score.MaxScore) * (score.Weight / totalWeight) * 100;
    const maxPercentage = (score.MaxScore / score.MaxScore) * (score.Weight / totalWeight) * 100;
    return (React.createElement("tr", null,
        React.createElement("td", { className: `${className} pl-4` }, score.TestName),
        React.createElement("td", { className: "text-right" },
            score.Score,
            "/",
            score.MaxScore),
        React.createElement("td", { className: "text-right" },
            React.createElement("span", { className: percentage === maxPercentage ? "text-success" : "text-danger" },
                percentage.toFixed(1),
                "%")),
        React.createElement("td", { className: "text-right" },
            React.createElement("span", { style: { opacity: 0.5 }, "data-toggle": "tooltip", title: `Weight: ${score.Weight}` },
                maxPercentage.toFixed(1),
                "%"))));
};
export default SubmissionScore;
