import React from 'react';
import { useAppState } from './overmind';
import NavBar from "./components/NavBar";
import { Switch, Route } from 'react-router-dom';
import Profile from "./components/profile/Profile";
import CoursePage from "./pages/CoursePage";
import Courses from "./components/Courses";
import AdminPage from './pages/AdminPage';
import Loading from './components/Loading';
import Dashboard from './components/Dashboard';
import AboutPage from './pages/AboutPage';
const App = () => {
    const state = useAppState();
    const Main = () => {
        if (state.isLoading) {
            return React.createElement(Loading, null);
        }
        else if (!state.isValid && state.isLoggedIn) {
            return (React.createElement(Switch, null,
                React.createElement(Route, { path: "/", component: Profile }),
                React.createElement(Route, { path: "/profile", component: Profile })));
        }
        else if (state.isLoggedIn) {
            return (React.createElement(Switch, null,
                React.createElement(Route, { path: "/", exact: true, component: Dashboard }),
                React.createElement(Route, { path: "/about", component: AboutPage }),
                React.createElement(Route, { path: "/profile", component: Profile }),
                React.createElement(Route, { path: "/course/:id", component: CoursePage }),
                React.createElement(Route, { path: "/courses", exact: true, component: Courses }),
                React.createElement(Route, { path: "/admin", component: AdminPage })));
        }
        else {
            return (React.createElement(Switch, null,
                React.createElement(Route, { path: "/", component: AboutPage })));
        }
    };
    return (React.createElement("div", null,
        React.createElement(NavBar, null),
        React.createElement("div", { className: "app wrapper" },
            React.createElement("div", { id: state.showFavorites ? "content" : "content-full" }, Main()))));
};
export default App;
