import React from "react";
import { useHistory } from "react-router";
import { Status } from "../../consts";
import { isStudent, isTeacher } from "../../Helpers";
import { useActions, useAppState } from "../../overmind";
import NavBarLabs from "./NavBarLabs";
import NavBarTeacher from "./NavBarTeacher";
const NavBarCourse = ({ enrollment }) => {
    const state = useAppState();
    const actions = useActions();
    const history = useHistory();
    const active = state.activeCourse === enrollment.courseID;
    const course = state.courses.find(c => c.ID === enrollment.courseID);
    const navigateTo = (courseID) => {
        if (active) {
            actions.setActiveCourse(BigInt(0));
            history.push("/");
        }
        else {
            history.push(`/course/${courseID}`);
            actions.setActiveCourse(courseID);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("li", { role: "button", onClick: () => navigateTo(enrollment.courseID), className: "activeClass" },
            React.createElement("div", { className: "col", id: "title" }, course?.code),
            React.createElement("div", { className: "col", title: "icon" },
                React.createElement("i", { className: active ? " icon fa fa-caret-down fa-lg float-right" : " icon fa fa-caret-down fa-rotate-90 fa-lg float-right" }))),
        React.createElement("div", { className: active ? Status.ActiveLab : Status.Inactive },
            active && isStudent(enrollment) ? React.createElement(NavBarLabs, null) : null,
            active && isTeacher(enrollment) ? React.createElement(NavBarTeacher, null) : null)));
};
export default NavBarCourse;
