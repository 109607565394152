import React, { useEffect } from "react";
import { isHidden, Color, userLink } from "../../Helpers";
import { useAppState, useActions } from "../../overmind";
import DynamicButton from "../DynamicButton";
import DynamicTable from "../DynamicTable";
import Search from "../Search";
import { ButtonType } from "./Button";
import User from "./User";
const Users = () => {
    const state = useAppState();
    const actions = useActions();
    useEffect(() => {
        actions.getUsers();
    }, []);
    const headers = ["Name", "GitHub", "Email", "Student ID", "Role"];
    const users = state.allUsers.map((user) => {
        const data = [];
        data.push(React.createElement(User, { user: user, hidden: !isHidden(user.Name, state.query) }));
        data.push(React.createElement("a", { href: userLink(user) }, user.Login));
        data.push(user.Email);
        data.push(user.StudentID);
        data.push(React.createElement(DynamicButton, { text: user.IsAdmin ? "Demote" : "Promote", color: user.IsAdmin ? Color.RED : Color.BLUE, type: ButtonType.BADGE, onClick: () => actions.updateAdmin(user) }));
        return data;
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: "pb-2" },
            React.createElement(Search, null)),
        React.createElement(DynamicTable, { header: headers, data: users })));
};
export default Users;
