import React from 'react';
import { useHistory } from 'react-router';
import { EnrollmentStatus, hasEnrolled, hasNone, hasPending } from '../Helpers';
import { useActions, useAppState } from '../overmind';
import CourseFavoriteButton from './CourseFavoriteButton';
const CardColor = [
    "info",
    "secondary",
    "primary",
    "success"
];
const CourseCard = ({ course, enrollment }) => {
    const state = useAppState();
    const actions = useActions();
    const history = useHistory();
    const currentYear = new Date().getFullYear();
    const status = enrollment.status;
    const CourseEnrollmentButton = () => {
        if (hasNone(status)) {
            if (course.year == currentYear || state.self.IsAdmin) {
                return React.createElement("div", { className: "btn btn-primary course-button", onClick: () => actions.enroll(course.ID) }, "Enroll");
            }
            else {
                return React.createElement("div", { className: "btn btn-secondary course-button disabled" }, "Not Available");
            }
        }
        else if (hasPending(status)) {
            return React.createElement("div", { className: "btn btn-secondary course-button disabled" }, "Pending");
        }
        return React.createElement("div", { className: "btn btn-primary course-button", onClick: () => history.push(`/course/${enrollment.courseID}`) }, "Go to Course");
    };
    const CourseEnrollmentStatus = () => {
        if (!hasEnrolled(status)) {
            return null;
        }
        return (React.createElement("div", { className: "d-flex align-items-center" },
            React.createElement(CourseFavoriteButton, { enrollment: enrollment, style: { marginLeft: 'auto' } }),
            React.createElement("p", { className: "mb-0 ml-2 text-white" }, EnrollmentStatus[status])));
    };
    const getColor = (status, course) => {
        if (course.year < currentYear && !state.self.IsAdmin && status == 0) {
            return "secondary";
        }
        return CardColor[status];
    };
    return (React.createElement("div", { className: "card course-card mb-4 shadow-sm" },
        React.createElement("div", { className: `card-header bg-${getColor(status, course)} text-white d-flex justify-content-between align-items-center` },
            React.createElement("span", null, course.code),
            React.createElement(CourseEnrollmentStatus, null)),
        React.createElement("div", { className: "card-body" },
            React.createElement("h5", { className: "card-title" }, course.name),
            React.createElement("p", { className: "card-text text-muted" },
                course.tag,
                " ",
                course.year),
            React.createElement(CourseEnrollmentButton, null))));
};
export default CourseCard;
