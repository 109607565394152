import React from "react";
import { useAppState } from "../overmind";
import Lab from "./Lab";
import ManageSubmissionStatus from "./ManageSubmissionStatus";
const LabResult = () => {
    const state = useAppState();
    if (!state.selectedSubmission) {
        return null;
    }
    return (React.createElement("div", { className: "lab-resize lab-sticky" },
        React.createElement(ManageSubmissionStatus, null),
        React.createElement("div", { className: "reviewLabResult mt-2" },
            React.createElement(Lab, null))));
};
export default LabResult;
