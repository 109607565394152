import React from "react";
import { useAppState } from "../../overmind";
import ProgressBar, { Progress } from "../ProgressBar";
import NavBarLink from "./NavBarLink";
import { useHistory } from "react-router";
import { Status } from "../../consts";
import { getStatusByUser, isApproved } from "../../Helpers";
const NavBarLabs = () => {
    const state = useAppState();
    const history = useHistory();
    if (!state.assignments[state.activeCourse.toString()] || !state.submissions[state.activeCourse.toString()]) {
        return null;
    }
    const submissionIcon = (assignment) => {
        const submission = state.submissions[state.activeCourse.toString()][assignment.order - 1];
        return (React.createElement("div", null,
            assignment.isGroupLab && React.createElement("i", { className: "fa fa-users", title: "Group assignment" }),
            isApproved(getStatusByUser(submission, state.self.ID)) && React.createElement("i", { className: "fa fa-check ml-2" })));
    };
    const getLinkClass = (assignment) => {
        return BigInt(state.selectedAssignmentID) === assignment.ID ? Status.Active : "";
    };
    const labLinks = state.assignments[state.activeCourse.toString()]?.map((assignment, index) => {
        const link = { link: { text: assignment.name, to: `/course/${state.activeCourse}/lab/${assignment.ID}` }, jsx: submissionIcon(assignment) };
        return (React.createElement("div", { className: getLinkClass(assignment), style: { position: "relative" }, key: assignment.ID.toString(), onClick: () => { history.push(link.link.to); } },
            React.createElement(NavBarLink, { link: link.link, jsx: link.jsx }),
            React.createElement(ProgressBar, { courseID: state.activeCourse.toString(), assignmentIndex: index, type: Progress.NAV })));
    });
    return React.createElement(React.Fragment, null, labLinks);
};
export default NavBarLabs;
