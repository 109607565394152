import React, { useState } from "react";
import { useAppState } from "../../overmind";
import DynamicTable from "../DynamicTable";
import CourseForm from "../forms/CourseForm";
const EditCourse = () => {
    const state = useAppState();
    const [course, setCourse] = useState();
    const courses = state.courses.map(c => {
        const selected = course?.ID === c.ID;
        const data = [];
        data.push(c.name);
        data.push(c.code);
        data.push(c.tag);
        data.push(c.year.toString());
        data.push(c.slipDays.toString());
        data.push(React.createElement("span", { className: selected ? "badge badge-danger clickable" : "badge badge-primary clickable", onClick: () => { selected ? setCourse(undefined) : setCourse(c); } }, selected ? "Cancel" : "Edit"));
        return data;
    });
    return (React.createElement("div", { className: "box" },
        React.createElement(DynamicTable, { header: ["Course", "Code", "Tag", "Year", "Slipdays", "Edit"], data: courses }),
        course ? React.createElement(CourseForm, { courseToEdit: course }) : null));
};
export default EditCourse;
