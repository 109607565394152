import React from "react";
import { GradingCriterion_Grade } from "../../../proto/qf/types_pb";
import { useActions, useAppState } from "../../overmind";
const CriteriaStatus = ({ criterion }) => {
    const { setGrade } = useActions().review;
    const { isTeacher } = useAppState();
    if (!isTeacher) {
        return null;
    }
    const buttons = [
        { icon: "fa fa-check", status: GradingCriterion_Grade.PASSED, style: "success", onClick: () => setGrade({ criterion: criterion, grade: GradingCriterion_Grade.PASSED }) },
        { icon: "fa fa-ban", status: GradingCriterion_Grade.NONE, style: "secondary", onClick: () => setGrade({ criterion: criterion, grade: GradingCriterion_Grade.NONE }) },
        { icon: "fa fa-times", status: GradingCriterion_Grade.FAILED, style: "danger", onClick: () => setGrade({ criterion: criterion, grade: GradingCriterion_Grade.FAILED }) },
    ];
    const StatusButtons = buttons.map((button, index) => {
        const style = criterion.grade === button.status ? `col btn-xs btn-${button.style} mr-2 border` : `col btn-xs btn-outline-${button.style} mr-2 border`;
        return (React.createElement("div", { key: index, className: style, onClick: () => button.onClick() },
            React.createElement("i", { className: button.icon })));
    });
    return React.createElement("div", { className: "btn-group" }, StatusButtons);
};
export default CriteriaStatus;
