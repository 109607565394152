import React, { useState } from "react";
import { GradingCriterion } from "../../../proto/qf/types_pb";
import { useActions } from "../../overmind";
const EditCriterion = ({ originalCriterion, benchmarkID, assignment }) => {
    const actions = useActions();
    const [editing, setEditing] = useState(false);
    const [add, setAdd] = useState(originalCriterion ? false : true);
    const criterion = originalCriterion
        ? originalCriterion.clone()
        : new GradingCriterion();
    const handleCriteria = (event) => {
        const { value } = event.currentTarget;
        if (event.key === "Enter") {
            criterion.BenchmarkID = benchmarkID;
            actions.createOrUpdateCriterion({ criterion: criterion, assignment: assignment });
            setEditing(false);
        }
        else {
            criterion.description = value;
        }
    };
    const handleBlur = () => {
        if (originalCriterion) {
            criterion.description = originalCriterion.description;
        }
        else {
            criterion.description = "";
            setAdd(true);
        }
        setEditing(false);
    };
    if (add) {
        return (React.createElement("div", { className: "list-group-item" },
            React.createElement("button", { className: "btn btn-primary", name: "submit", onClick: () => { setAdd(false); setEditing(true); } }, "Add")));
    }
    return (React.createElement("div", { className: "list-group-item", onClick: () => setEditing(!editing) }, editing
        ? React.createElement("input", { className: "form-control", type: "text", onBlur: () => { handleBlur(); }, autoFocus: true, defaultValue: criterion.description, name: "criterion", onKeyUp: e => { handleCriteria(e); } })
        : React.createElement(React.Fragment, null,
            React.createElement("span", null, criterion.description),
            React.createElement("span", { className: "badge badge-danger float-right clickable", onClick: () => actions.deleteCriterion({ criterion: originalCriterion, assignment: assignment }) }, "Delete"))));
};
export default EditCriterion;
