import React from 'react';
import { render } from 'react-dom';
import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import { config } from './overmind';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './style.scss';
BigInt.prototype.toJSON = function () {
    return this.toString();
};
const overmind = createOvermind(config, {
    devtools: "localhost:3301",
});
render((React.createElement(Provider, { value: overmind },
    React.createElement(BrowserRouter, null,
        React.createElement(App, null)))), document.getElementById('root'));
