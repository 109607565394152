import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useActions, useAppState } from '../../overmind';
import useWindowSize from "../../hooks/windowsSize";
import { ScreenSize } from "../../consts";
const Breadcrumbs = () => {
    const state = useAppState();
    const actions = useActions();
    const location = useLocation();
    const { width } = useWindowSize();
    const [courseName, setCourseName] = useState(null);
    const [assignmentName, setAssignmentName] = useState(null);
    const pathnames = location.pathname.split('/').filter(x => x);
    const getCourseNameById = (id) => {
        const course = state.courses.find(course => course.ID.toString() === id);
        if (!course) {
            return null;
        }
        if (width < ScreenSize.ExtraLarge) {
            return course.code;
        }
        return course.name;
    };
    const getAssignmentNameById = (id) => {
        if (pathnames[0] === 'course' && pathnames[1]) {
            const assignment = state.assignments[pathnames[1]].find(assignment => assignment.ID.toString() === id);
            return assignment ? assignment.name : null;
        }
        return null;
    };
    const handleDashboard = () => {
        actions.setActiveCourse(0n);
    };
    useEffect(() => {
        if (pathnames[0] === 'course' && pathnames[1]) {
            setCourseName(getCourseNameById(pathnames[1]));
        }
        if (pathnames[2] === 'lab' && pathnames[3]) {
            setAssignmentName(getAssignmentNameById(pathnames[3]));
        }
    }, [pathnames, width]);
    return (React.createElement("nav", { "aria-label": "breadcrumb" },
        React.createElement("ol", { className: "breadcrumb m-0 bg-transparent" },
            React.createElement("li", { className: "breadcrumb-item" },
                React.createElement(Link, { to: "/", onClick: handleDashboard }, "Dashboard")),
            pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                let breadcrumbName = decodeURIComponent(value.charAt(0).toUpperCase() + value.slice(1));
                if (index === 0 && value === 'course') {
                    return null;
                }
                if (index === 2 && value === 'lab') {
                    return null;
                }
                if (index === 1 && courseName && pathnames[0] === 'course') {
                    breadcrumbName = courseName;
                }
                if (index === 3 && assignmentName && pathnames[2] === 'lab') {
                    breadcrumbName = assignmentName;
                }
                return last ? (React.createElement("li", { key: to, className: "breadcrumb-item active", "aria-current": "page" }, breadcrumbName)) : (React.createElement("li", { key: to, className: "breadcrumb-item" },
                    React.createElement(Link, { to: to }, breadcrumbName)));
            }))));
};
export default Breadcrumbs;
