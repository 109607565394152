import React from "react";
import { Color } from "../../Helpers";
import { useActions, useAppState } from "../../overmind";
import Button, { ButtonType } from "../admin/Button";
const MarkReadyButton = ({ review }) => {
    const allCriteriaGraded = useAppState((state) => state.review.graded === state.review.criteriaTotal);
    const actions = useActions();
    const ready = review.ready;
    const handleMarkReady = React.useCallback(() => {
        if (allCriteriaGraded || ready) {
            actions.review.updateReady(!ready);
        }
    }, [allCriteriaGraded, ready]);
    return (React.createElement(Button, { text: ready ? "Mark In progress" : "Mark Ready", color: ready ? Color.YELLOW : Color.GREEN, type: ready ? ButtonType.BADGE : ButtonType.BUTTON, className: ready ? "float-right" : allCriteriaGraded ? "" : "disabled", onClick: handleMarkReady }));
};
export default MarkReadyButton;
