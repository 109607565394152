import React from "react";
import { Submission_Status } from "../../../proto/qf/types_pb";
import { NoSubmission } from "../../consts";
import { Color, getFormattedTime, getStatusByUser, SubmissionStatus } from "../../Helpers";
import { useActions, useAppState } from "../../overmind";
import { ButtonType } from "../admin/Button";
import DynamicButton from "../DynamicButton";
import ManageSubmissionStatus from "../ManageSubmissionStatus";
import MarkReadyButton from "./MarkReadyButton";
const ReviewInfo = ({ review }) => {
    const state = useAppState();
    const actions = useActions();
    if (!review) {
        return null;
    }
    const assignment = state.selectedAssignment;
    const submission = state.selectedSubmission;
    const ready = review.ready;
    const markReadyButton = React.createElement(MarkReadyButton, { review: review });
    const user = state.selectedEnrollment?.user;
    let status = Submission_Status.NONE;
    let userLi = null;
    if (user) {
        status = getStatusByUser(submission, user.ID);
        userLi = (React.createElement("li", { className: "list-group-item" },
            React.createElement("span", { className: "w-25 mr-5 float-left" }, "User: "),
            user.Name));
    }
    const setReadyOrGradeButton = ready ? React.createElement(ManageSubmissionStatus, null) : markReadyButton;
    const releaseButton = (React.createElement(DynamicButton, { text: submission?.released ? "Released" : "Release", color: submission?.released ? Color.WHITE : Color.YELLOW, type: ButtonType.BUTTON, className: `float-right ${!state.isCourseCreator && "disabled"} `, onClick: () => actions.review.release({ submission, owner: state.submissionOwner }) }));
    return (React.createElement("ul", { className: "list-group" },
        React.createElement("li", { className: "list-group-item active" },
            React.createElement("span", { className: "align-middle" },
                React.createElement("span", { style: { display: "inline-block" }, className: "w-25 mr-5 p-3" }, assignment?.name),
                releaseButton)),
        userLi,
        React.createElement("li", { className: "list-group-item" },
            React.createElement("span", { className: "w-25 mr-5 float-left" }, "Reviewer: "),
            state.review.reviewer?.Name),
        React.createElement("li", { className: "list-group-item" },
            React.createElement("span", { className: "w-25 mr-5 float-left" }, "Submission Status: "),
            submission ? SubmissionStatus[status] : { NoSubmission }),
        React.createElement("li", { className: "list-group-item" },
            React.createElement("span", { className: "w-25 mr-5 float-left" }, "Review Status: "),
            React.createElement("span", null, ready ? "Ready" : "In progress"),
            ready && markReadyButton),
        React.createElement("li", { className: "list-group-item" },
            React.createElement("span", { className: "w-25 mr-5 float-left" }, "Score: "),
            review.score),
        React.createElement("li", { className: "list-group-item" },
            React.createElement("span", { className: "w-25 mr-5 float-left" }, "Updated: "),
            getFormattedTime(review.edited)),
        React.createElement("li", { className: "list-group-item" },
            React.createElement("span", { className: "w-25 mr-5 float-left" }, "Graded: "),
            state.review.graded,
            "/",
            state.review.criteriaTotal),
        React.createElement("li", { className: "list-group-item" }, setReadyOrGradeButton)));
};
export default ReviewInfo;
