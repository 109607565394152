import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
const CriterionComment = ({ comment }) => {
    if (comment == "" || comment.length == 0) {
        return null;
    }
    return (React.createElement("div", { className: "comment-md" },
        React.createElement(ReactMarkdown, { children: comment, components: {
                code({ node, inline, className, children, ...props }) {
                    const matchLanguage = /language-(\w+)/.exec(className || '');
                    return !inline && matchLanguage ? (React.createElement(SyntaxHighlighter, { children: String(children).replace(/\n$/, ''), language: matchLanguage[1], PreTag: "div", ...props, style: oneDark })) : (React.createElement("code", { className: className, ...props }, children));
                }
            } })));
};
export default CriterionComment;
