import React from "react";
import { Link } from "react-router-dom";
import { Repository_Type } from "../../proto/qf/types_pb";
import { getCourseID } from "../Helpers";
import { useAppState } from "../overmind";
const CourseLinks = () => {
    const state = useAppState();
    const courseID = getCourseID();
    const enrollment = state.enrollmentsByCourseID[courseID.toString()];
    const repo = state.repositories[courseID.toString()];
    return (React.createElement("div", { className: "col-lg-3" },
        React.createElement("div", { className: "list-group width-resize" },
            React.createElement("div", { className: "list-group-item list-group-item-action active text-center" },
                React.createElement("h6", null,
                    React.createElement("strong", null, "Links"))),
            React.createElement("a", { href: repo[Repository_Type.USER], target: "_blank", rel: "noopener noreferrer", className: "list-group-item list-group-item-action" }, "User Repository"),
            repo[Repository_Type.GROUP] ? (React.createElement("a", { href: repo[Repository_Type.GROUP], target: "_blank", rel: "noopener noreferrer", className: "list-group-item list-group-item-action overflow-ellipses", style: { textAlign: "left" } },
                "Group Repository (",
                enrollment.group?.name,
                ")")) : null,
            React.createElement("a", { href: repo[Repository_Type.ASSIGNMENTS], target: "_blank", rel: "noopener noreferrer", className: "list-group-item list-group-item-action" }, "Assignments"),
            React.createElement("a", { href: repo[Repository_Type.INFO], target: "_blank", rel: "noopener noreferrer", className: "list-group-item list-group-item-action" }, "Course Info"),
            state.hasGroup(courseID.toString()) ? (React.createElement(Link, { to: `/course/${courseID}/group`, className: "list-group-item list-group-item-action" }, "View Group")) : (React.createElement(Link, { to: `/course/${courseID}/group`, className: "list-group-item list-group-item-action list-group-item-success" }, "Create a Group")))));
};
export default CourseLinks;
