import React from "react";
import AboutButton from "../navbar-buttons/AboutButton";
import AdminButton from "../navbar-buttons/AdminButton";
import { useAppState } from "../../overmind";
import ProfileButton from "../navbar-buttons/ProfileButton";
import LogoutButton from "../navbar-buttons/LogoutButton";
import StreamStatus from "./StreamStatus";
const NavBarUser = () => {
    const { self, isLoggedIn } = useAppState();
    if (!isLoggedIn) {
        return (React.createElement("ul", null,
            React.createElement("a", { href: "/auth/github", className: "signIn", style: { textAlign: "right", color: "#d4d4d4", marginRight: "55px" } },
                React.createElement("i", { className: "fa fa-2x fa-github align-middle ms-auto ", id: "github" }))));
    }
    return (React.createElement("div", { className: "flex-user" },
        React.createElement(StreamStatus, null),
        React.createElement("ul", { className: "nav-item dropdown" },
            React.createElement("img", { className: "rounded-circle", src: self.AvatarURL, id: "avatar" }),
            React.createElement("ul", { className: "dropdown-menu dropdown-menu-center bg-dark" },
                React.createElement(ProfileButton, null),
                React.createElement(AboutButton, null),
                React.createElement(AdminButton, null),
                React.createElement(LogoutButton, null)))));
};
export default NavBarUser;
