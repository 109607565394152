import React from "react";
import { Redirect } from "react-router";
import { hasEnrollment } from "../Helpers";
import { useAppState } from "../overmind";
import Alerts from "./alerts/Alerts";
import Courses from "./Courses";
import SubmissionsTable from "./dashboard/SubmissionsTable";
const Dashboard = () => {
    const state = useAppState();
    if (!hasEnrollment(state.enrollments)) {
        return React.createElement(Redirect, { to: "/courses" });
    }
    return (React.createElement("div", { className: 'box' },
        React.createElement(Alerts, null),
        React.createElement("div", null,
            React.createElement("h1", null,
                "Welcome, ",
                state.self.Name,
                "!")),
        React.createElement(SubmissionsTable, null),
        React.createElement(Courses, { home: true })));
};
export default Dashboard;
