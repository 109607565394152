import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { Enrollment } from "../../proto/qf/types_pb";
import { Color, getCourseID, getSubmissionCellColor } from "../Helpers";
import { useActions, useAppState } from "../overmind";
import Button, { ButtonType } from "./admin/Button";
import { generateAssignmentsHeader, generateSubmissionRows } from "./ComponentsHelpers";
import DynamicTable from "./DynamicTable";
import TableSort from "./forms/TableSort";
import LabResult from "./LabResult";
import ReviewForm from "./manual-grading/ReviewForm";
import Release from "./Release";
import Search from "./Search";
const Results = ({ review }) => {
    const state = useAppState();
    const actions = useActions();
    const courseID = getCourseID();
    const history = useHistory();
    const location = useLocation();
    const members = useMemo(() => { return state.courseMembers; }, [state.courseMembers, state.groupView]);
    const assignments = useMemo(() => {
        return state.assignments[courseID.toString()]?.filter(a => state.review.assignmentID <= 0 || a.ID === state.review.assignmentID) ?? [];
    }, [state.assignments, courseID, state.review.assignmentID]);
    useEffect(() => {
        if (!state.loadedCourse[courseID.toString()]) {
            actions.loadCourseSubmissions(courseID);
        }
        return () => {
            actions.setGroupView(false);
            actions.review.setAssignmentID(-1n);
            actions.setActiveEnrollment(null);
        };
    }, []);
    useEffect(() => {
        if (!state.selectedSubmission) {
            const selectedLab = new URLSearchParams(location.search).get('id');
            if (selectedLab) {
                const submission = state.submissionsForCourse.ByID(BigInt(selectedLab));
                if (submission) {
                    actions.setSelectedSubmission(submission);
                    actions.updateSubmissionOwner(state.submissionsForCourse.OwnerByID(submission.ID));
                }
            }
        }
    }, []);
    const handleLabClick = useCallback((labId) => {
        history.replace({
            pathname: location.pathname,
            search: `?id=${labId}`
        });
    }, [history]);
    if (!state.loadedCourse[courseID.toString()]) {
        return React.createElement("h1", null, "Fetching Submissions...");
    }
    const generateReviewCell = (submission, owner) => {
        if (!state.isManuallyGraded(submission)) {
            return { value: "N/A" };
        }
        const reviews = state.review.reviews.get(submission.ID) ?? [];
        const pending = reviews.some((r) => !r.ready && r.ReviewerID === state.self.ID);
        const isSelected = state.selectedSubmission?.ID === submission.ID;
        const score = reviews.reduce((acc, theReview) => acc + theReview.score, 0) / reviews.length;
        const willBeReleased = state.review.minimumScore > 0 && score >= state.review.minimumScore;
        const numReviewers = state.assignments[state.activeCourse.toString()]?.find((a) => a.ID === submission.AssignmentID)?.reviewers ?? 0;
        return ({
            value: `${reviews.length}/${numReviewers} ${submission.released ? "(r)" : ""}`,
            className: `${getSubmissionCellColor(submission, owner)} ${isSelected ? "selected" : ""} ${willBeReleased ? "release" : ""} ${pending ? "pending-review" : ""}`,
            onClick: () => {
                actions.setSelectedSubmission(submission);
                if (owner instanceof Enrollment) {
                    actions.setActiveEnrollment(owner.clone());
                }
                actions.setSubmissionOwner(owner);
                actions.review.setSelectedReview(-1);
                handleLabClick(submission.ID);
            }
        });
    };
    const getSubmissionCell = (submission, owner) => {
        const isSelected = state.selectedSubmission?.ID === submission.ID;
        return ({
            value: `${submission.score} %`,
            className: `${getSubmissionCellColor(submission, owner)} ${isSelected ? "selected" : ""}`,
            onClick: () => {
                actions.setSelectedSubmission(submission);
                if (owner instanceof Enrollment) {
                    actions.setActiveEnrollment(owner.clone());
                }
                actions.setSubmissionOwner(owner);
                handleLabClick(submission.ID);
                actions.getSubmission({ submission: submission, owner: state.submissionOwner, courseID: state.activeCourse });
            }
        });
    };
    const groupView = state.groupView;
    const header = generateAssignmentsHeader(assignments, groupView);
    const generator = review ? generateReviewCell : getSubmissionCell;
    const rows = generateSubmissionRows(members, generator);
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: `p-0 ${state.review.assignmentID >= 0 ? "col-md-4" : "col-md-6"}` },
            review ? React.createElement(Release, null) : null,
            React.createElement(Search, { placeholder: "Search by name ...", className: "mb-2" },
                React.createElement(Button, { text: `View by ${groupView ? "student" : "group"}`, color: groupView ? Color.BLUE : Color.GREEN, type: ButtonType.BUTTON, className: "ml-2", onClick: () => { actions.setGroupView(!groupView); actions.review.setAssignmentID(BigInt(-1)); } })),
            React.createElement(TableSort, { review: review }),
            React.createElement(DynamicTable, { header: header, data: rows })),
        React.createElement("div", { className: "col" }, review ? React.createElement(ReviewForm, null) : React.createElement(LabResult, null))));
};
export default Results;
